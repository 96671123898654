.normal-rounded-carousel .carousel .control-arrow,
.normal-rounded-carousel .carousel.carousel-slider .control-arrow {
	color: white;
	background-color: transparent;
}

.normal-rounded-carousel .carousel .control-arrow,
.normal-rounded-carousel .carousel.carousel-slider .control-arrow:hover {
	opacity: 1;
	background-color: rgba(248, 70, 84, 0.7);
}

.normal-rounded-carousel .carousel .control-next {
	border-bottom-right-radius: 24px;
	border-top-right-radius: 24px;
}

.normal-rounded-carousel .carousel .control-prev {
	border-bottom-left-radius: 24px;
	border-top-left-radius: 24px;
}

.normal-rounded-carousel .carousel.carousel-slider .control-arrow {
	top: 0;
	bottom: 0;
	font-size: 26px;
	margin: auto;
	padding: 5px;
}
.normal-carousel .carousel .control-arrow,
.normal-carousel .carousel.carousel-slider .control-arrow {
	color: white;
	background-color: transparent;
}

.normal-carousel .carousel .control-arrow,
.normal-carousel .carousel.carousel-slider .control-arrow:hover {
	opacity: 1;
	background-color: rgba(248, 70, 84, 0.7);
}

.normal-carousel .carousel.carousel-slider .control-arrow {
	top: 0;
	bottom: 0;
	font-size: 26px;
	margin: auto;
	padding: 5px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-color: #ece8e0;
}

img {
	pointer-events: none;
}

button {
	-webkit-tap-highlight-color: transparent;
}

input {
	font-weight: 500;
}

.carousel-cover {
	background-color: transparent;
	transition: all 300ms;
}

.carousel-cover:hover {
	background-color: rgba(0, 0, 0, 0.3);
	transition: all 300ms;
}

.carousel-cover-title {
	color: transparent;
	transition: all 300ms;
}

.carousel-cover:hover .carousel-cover-title {
	color: white;
	transition: all 300ms;
}

.carousel-cover-button {
	font-weight: 800;
	width: 0;
	color: transparent;
	overflow: hidden;
	text-overflow: clip;
	white-space: nowrap;
	transition: all 300ms;
}

.carousel-cover:hover .carousel-cover-button {
	width: 200px;
	color: white;
	overflow: hidden;
	text-overflow: clip;
	white-space: nowrap;
	transition: all 300ms;
}

@media only screen and (min-width: 600px) {
	.carousel-cover:hover .carousel-cover-button {
		width: 200px;
	}
}

@media only screen and (min-width: 1280px) {
	.carousel-cover:hover .carousel-cover-button {
		width: 300px;
	}
}
